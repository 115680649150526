<template>
  <div>
    <div class="card shadow" @click="focus = null">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getVivaioList')">
          <div class="row">
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Persona"
                  name="nominativo_completo"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'persona';
                    getPersone($event.target.value);
                  "
                  :value="personaDenominazioneVivaio"
                  autocomplete="off"
                />
                <svg
                  @click="setIdPersonaVivaio({ name: null, value: null })"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListTesserati
                v-if="inputFocus === 'persona'"
                :focusOnInput="focus === 1"
                :list="persone"
                @hideList="
                  inputFocus = null;
                  resetPersone;
                "
                @selectedTesserato="
                  resetPersone();
                  setIdPersonaVivaio($event);
                  inputFocus = null;
                "
              ></ListTesserati>
            </div>
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società"
                  name="denominazione_societa"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'societa';
                    getSocieta($event.target.value);
                  "
                  :value="societaDenominazioneVivaio"
                  autocomplete="off"
                />
                <svg
                  @click="setIdSocietaVivaio({ name: null, value: null })"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :focusOnInput="inputFocus === 'societa'"
                :list="societa"
                @hideList="resetSocieta"
                @selectedSocieta="
                  resetSocieta();
                  setIdSocietaVivaio($event);
                "
              ></ListSocieta>
            </div>
          </div>
          <div class="text-center mt-5">
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getVivaioList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i
            ></span>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";
import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";

export default defineComponent({
  name: "filtri-vivaio",
  emits: ["resetFilters", "getVivaioList"],
  props: {
    resetted: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
  },
  components: { ListTesserati, ListSocieta },
  setup(props, { emit }) {
    const store = useStore();

    const idSocietaVivaio = computed(() => store.getters.idSocietaVivaio);
    const idPersonaVivaio = computed(() => store.getters.idPersonaVivaio);
    const societaDenominazioneVivaio = computed(
      () => store.getters.societaDenominazioneVivaio
    );
    const personaDenominazioneVivaio = computed(
      () => store.getters.personaDenominazioneVivaio
    );

    const setIdSocietaVivaio = ({ value, name }) => {
      store.commit("setIdSocietaVivaio", value);
      store.commit("setSocietaDenominazioneVivaio", name);
    };

    const setIdPersonaVivaio = ({ value, name }) => {
      store.commit("setIdPersonaVivaio", value);
      store.commit("setPersonaDenominazioneVivaio", name);
    };

    const getSocieta = (string) => {
      store.commit("setSocietaDenominazioneVivaio", string);
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const getPersone = (string) => {
      store.commit("setPersonaDenominazioneVivaio", string);
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetPersone = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const focus = ref(null);

    const inputFocus = ref(null);

    return {
      inputFocus,
      getSocieta,
      resetSocieta,
      getPersone,
      resetPersone,
      persone: computed(() => store.getters.personeSocieta),
      societa: computed(() => store.getters.societaNomeCodAff),
      societaDenominazioneVivaio,
      personaDenominazioneVivaio,
      idSocietaVivaio,
      idPersonaVivaio,
      setIdSocietaVivaio,
      setIdPersonaVivaio,
      focus,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedvivaio_list")
      ),
    };
  },
});
</script>

<style></style>
