<template>
  <div>
    <div class="row mt-5">
      <div class="col-6">
        <div v-if="!readOnly">
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add-vivaio"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>inserimento vivaio
          </button>
          <AddVivaio @refreshList="refreshList" />
        </div>
      </div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzate
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />

    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        vivaio_list.length === 0 && loaded && (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="vivaio_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsVivaio"
        @current-change="setCurrentPageVivaio"
      >
        <template v-slot:cell-nominativo_completo="{ row: vivaio_list }"
          ><div class="tab-long">
            {{ vivaio_list.nominativo_completo }}
          </div></template
        >
        <template v-slot:cell-denominazione="{ row: vivaio_list }"
          ><div class="tab-long">
            {{ vivaio_list.denominazione }} ({{ vivaio_list.cod_affiliazione }})
          </div>
        </template>
        <template v-slot:cell-stagioni_string="{ row: vivaio_list }"
          >{{ vivaio_list.stagioni_string }}
        </template>
        <template v-slot:cell-options="{ row: vivaio_list }">
          <div v-if="!readOnly" class="btn-group">
            <button
              class="btn btn-sm dropdown p-1"
              data-bs-toggle="modal"
              data-bs-target="#modal_edit_vivaio"
              @click="
                selectedIdPersona = vivaio_list.id_persona;
                selectedIdSocieta = vivaio_list.id_societa;
                selectedPersona = vivaio_list.nominativo_completo;
                selectedSocieta = vivaio_list.denominazione;
                stagioniSelected = JSON.parse(vivaio_list.stagioni);
              "
            >
              <i class="bi bi-pen text-dark fs-5"></i>
            </button>
          </div>
        </template>
      </Datatable>
    </div>
    <EditVivaio
      :id_persona_props="selectedIdPersona"
      :id_societa_props="selectedIdSocieta"
      :nominativo_completo_props="selectedPersona"
      :societa_props="selectedSocieta"
      :stagioni_props="stagioniSelected"
      @refreshList="refreshList"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import AddVivaio from "./AddVivaio.vue";
import EditVivaio from "./EditVivaio.vue";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "table-vivaio",
  components: { Datatable, AddVivaio, EditVivaio },
  emits: ["getVivaioList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();

    const selectedIdPersona = ref(null);
    const selectedIdSocieta = ref(null);
    const selectedPersona = ref(null);
    const selectedSocieta = ref(null);
    const stagioniSelected = ref(null);

    const setFetchRowsVivaio = (e) => {
      store.commit("setFetchRowsVivaio", e);
      emit("getVivaioList");
    };
    const setCurrentPageVivaio = (page) => {
      store.commit("setCurrentPageVivaio", page);
      emit("getVivaioList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnVivaio", columnName);
      store.commit("setSortOrderVivaio", order);
      emit("getVivaioList");
    };

    const refreshList = () => {
      emit("getVivaioList");
    };

    const tableHeader = ref([
      {
        name: "Tesserato",
        key: "nominativo_completo",
      },
      {
        name: "Società",
        key: "denominazione",
      },
      {
        name: "Stagioni",
        key: "stagioni_string",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    return {
      tableHeader,
      selectedIdPersona,
      selectedIdSocieta,
      selectedPersona,
      selectedSocieta,
      isEnabled,
      stagioniSelected,
      vivaio_list: computed(() =>
        store.getters.getStateFromName("resultsvivaio_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedvivaio_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordvivaio_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusvivaio_list")
      ),
      currentPage: computed(() => store.getters.currentPageVivaio),
      rowsToSkip: computed(() => store.getters.rowsToSkipVivaio),
      fetchRows: computed(() => store.getters.fetchRowsVivaio),
      sortColumn: computed(() => store.getters.sortColumnVivaio),
      sortOrder: computed(() => store.getters.sortOrderVivaio),
      setFetchRowsVivaio,
      setCurrentPageVivaio,
      setSortOrderColumn,
      refreshList,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>

<style scoped></style>
