<template>
  <div>
    <FilterVivaio
      @getVivaioList="searchVivaioList"
      @resetFilters="resetFilters"
    />
    <TableVivaio
      @getVivaioList="getVivaioList"
      @resetFilters="resetFilters"
      :getVivaioList="getVivaioList"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, getCurrentInstance, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FilterVivaio from "@/components/components-fit/tesserati/vivaio/FiltriVivaio.vue";
import TableVivaio from "@/components/components-fit/tesserati/vivaio/TableVivaio.vue";

import { useStore } from "vuex";

export default defineComponent({
  name: "vivaio",
  components: {
    FilterVivaio,
    TableVivaio,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Vivaio", []);
    });

    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const currentPage = computed(() => store.getters.currentPageVivaio);
    const rowstoskip = computed(() => store.getters.rowsToSkipVivaio);
    const fetchrows = computed(() => store.getters.fetchRowsVivaio);
    const sortcolumn = computed(() => store.getters.sortColumnVivaio);
    const sortorder = computed(() => store.getters.sortOrderVivaio);
    const id_Persona = computed(() => store.getters.idPersonaVivaio);
    const id_Societa = computed(() => store.getters.idSocietaVivaio);

    const getVivaioList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_Persona: id_Persona.value,
          id_Societa: id_Societa.value,
          rowstoskip: rowstoskip.value,
          fetchrows: fetchrows.value,
          sortcolumn: sortcolumn.value,
          sortorder: sortorder.value,
        },
        apiLink: globalApi.VIVAIO_LIST,
        itemName: "vivaio_list",
      });
    };
    // reset filtri. viene attivato tramite evento @resetFilters
    const resetFilters = () => {
      store.commit("resetFiltersVivaio");
      store.commit("resetObjectFromName", "Vivaio_gruppi");
      getVivaioList();
    };
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedvivaio_list")
    );

    const searchVivaioList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipVivaio");
      getVivaioList();
    };

    getVivaioList();

    return { getVivaioList, resetFilters, currentPage, searchVivaioList };
  },
});
</script>
