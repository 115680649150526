<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_edit_vivaio"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Modifica vivaio</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="off">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row mb-4">
                    <div class="col-md-6">
                      <label class="fw-bold text-gray-600">Persona* </label>
                      <div class="dp__input_wrap">
                        <input
                          placeholder=""
                          name="nome_persona"
                          type="text"
                          class="form-control"
                          v-model="nome_persona"
                          autocomplete="off"
                          readonly
                        />
                        <Field
                          name="id_Persona"
                          type="hidden"
                          v-model="id_Persona"
                          class="form-control"
                        />
                      </div>
                      <!-- <ListTesserati
                      :list="tesseratiList"
                      @hideList="resetTesseratiList"
                      @selectedTesserato="
                        resetTesseratiList();
                        nome_persona = $event.name;
                        id_Persona = $event.value;
                      "
                    ></ListTesserati> -->
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_Persona"
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="fw-bold text-gray-600">Società* </label>
                      <div class="dp__input_wrap">
                        <input
                          placeholder=""
                          name="nome_societa"
                          type="text"
                          class="form-control"
                          v-model="nome_societa"
                          autocomplete="off"
                          readonly
                        />
                        <Field
                          name="id_Societa"
                          type="hidden"
                          v-model="id_Societa"
                          class="form-control"
                        />
                      </div>
                      <!-- <ListSocieta
                      :focusOnInput="true"
                      :list="societa"
                      @hideList="resetSocieta"
                      @selectedSocieta="
                        resetSocieta();
                        nome_societa = $event.name;
                        id_Societa = $event.value;
                      "
                    ></ListSocieta> -->
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_Societa"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-12">
                      <label class="fw-bold text-gray-600">Stagioni*</label>
                      <Multiselect
                        v-model="selectedStagioni"
                        :options="stagioniOptions"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        @input="inputElement"
                      />
                      <Field
                        name="stagioni"
                        type="hidden"
                        v-model="stagioni"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="stagioni"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalEditVivaio"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import "vue3-date-time-picker/dist/main.css";
import Multiselect from "@vueform/multiselect";
import { editVivaio } from "../../../../requests/vivaio";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "edit-vivaio",
  emits: ["refreshList"],
  components: {
    Loading,
    Form,
    ErrorMessage,
    Field,
    Multiselect,
  },
  props: {
    id_persona_props: {
      type: Number,
    },
    id_societa_props: {
      type: Number,
    },
    nominativo_completo_props: {
      type: String,
    },
    societa_props: {
      type: String,
    },
    stagioni_props: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const alertCampiObb = ref(false);

    const nome_persona = ref(null);
    const id_Persona = ref(null);

    // const getTesserati = (string) => {
    //   if (string === "") {
    //     store.dispatch("svuotaPersoneNomeCognome");
    //     return;
    //   } else if (string.length < 3) return;
    //   store.dispatch("getPersoneNomeCognome", string);
    // };
    // const resetTesseratiList = () => {
    //   store.dispatch("svuotaPersoneNomeCognome");
    // };

    // const spliceTesserati = () => {
    //   nome_persona.value = "";
    //   id_Persona.value = 0;
    //   resetTesseratiList();
    // };

    const nome_societa = ref("");
    const id_Societa = ref(null);

    // const getSocieta = (string) => {
    //   if (string === "") {
    //     store.commit("emptySocietaNomeCodAff");
    //     return;
    //   } else if (string.length < 3) return;
    //   store.dispatch("getSocietaNomeCodAff", string);
    // };
    // const resetSocieta = () => {
    //   store.commit("emptySocietaNomeCodAff");
    // };
    // const spliceSocieta = () => {
    //   nome_societa.value = "";
    //   id_Societa.value = 0;
    //   resetSocieta();
    // };

    const stagioni = ref("");
    const id_stagioniObj = ref(null);
    const stagioniOptions = computed(() => store.getters.stagioniOptions);
    const selectedStagioni = ref([]);

    watch(
      () => props.id_persona_props,
      (value) => {
        if (value) {
          alertCampiObb.value = false;
          selectedStagioni.value = [];
          id_Persona.value = props.id_persona_props;
          nome_persona.value = props.nominativo_completo_props;
          id_Societa.value = props.id_societa_props;
          nome_societa.value = props.societa_props;
          id_stagioniObj.value = props.stagioni_props;
          id_stagioniObj.value.forEach((element) => {
            selectedStagioni.value.push([element.id]);
            inputElement(selectedStagioni.value);
          });
        }
      }
    );

    const inputElement = (selected) => {
      stagioni.value = selected.join(",");
    };

    const schema = yup.object().shape({
      id_Persona: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable()
        .moreThan(0, "Obbligatorio"),
      id_Societa: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable()
        .moreThan(0, "Obbligatorio"),
      stagioni: yup.string().required("Obbligatorio").nullable(),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      alertCampiObb.value = false;
      const responseAdd = ref({});
      responseAdd.value = editVivaio(values);
      responseAdd.value.then((value) => {
        if (value.status == 200) {
          Swal.fire({
            html: "Vivaio modificato con successo.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          document.getElementById("closeModalEditVivaio").click();
          emit("refreshList");
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      // spliceTesserati();
      // spliceSocieta();
      selectedStagioni.value = [];
      nome_persona.value = "";
      nome_societa.value = "";
      id_Persona.value = null;
      id_Societa.value = null;
      stagioni.value = "";
    };

    return {
      stagioniOptions,
      nome_persona,
      nome_societa,
      id_Persona,
      id_Societa,
      // getTesserati,
      // getSocieta,
      // resetTesseratiList,
      // resetSocieta,
      // spliceTesserati,
      // spliceSocieta,
      tesseratiList: computed(() => store.getters.personeSocieta),
      societa: computed(() => store.getters.societaNomeCodAff),
      schema,
      onSubmit,
      onInvalidSubmit,
      resetField,
      alertCampiObb,
      selectedStagioni,
      stagioni,
      inputElement,
      disableButton,
      isLoading,
    };
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
